import React from "react";
import loadable from "@loadable/component";
import Layout from "../components/layout";

import BannerImg from "../images/careers_banner_img.png";

const Banner = loadable(() => import("../components/Banner/Banner"));
const CareersIntro = loadable(() => import("../components/CareersIntro/CareersIntro"));
const TileBlockRight = loadable(() => import("../components/TileBlockRight/TileBlockRight"));
const CareersPosition = loadable(() => import("../components/CareersPosition/CareersPosition"));

const Careers = (props) => {
    return (
        <Layout>
            <div className="layout-padding-top"></div>

            <Banner 
                bannerImg={BannerImg}
                tag={"careers-page"}
            />

            <CareersIntro />

            <TileBlockRight 
                tag={"careers-page"}
            />

            <CareersPosition />
        </Layout>
    )
}

export default Careers